<template>
	<!-- GAME SECTION -->
	<v-container no-gutters fluid class="pa-0 ma-0">
		<div class="pa-4" v-if="game.isLoading">
			<v-progress-circular :size="50" color="white" indeterminate></v-progress-circular>
		</div>

		<div class="pa-4" v-if="game.isExpired">
			<v-alert border="left" colored-border type="warning" elevation="2" :value="true">
				Desculpe, seu tempo acabou. Clique em recomeçar para realizar o teste novamente.
			</v-alert>

			<v-btn color="primary" class="mt-4" @click="$router.push('/inicio')">Recomeçar</v-btn>
		</div>

		<div class="pa-4" v-if="game.notEnabled">
			<v-alert border="left" colored-border type="info" elevation="2" :value="true">
				Desculpe, você não pode realizar o teste novamente.
			</v-alert>

			<v-btn color="primary" class="mt-4" @click="$router.push('/inicio')">Voltar</v-btn>
		</div>
		
		<div class="pa-0" v-if="!game.isExpired && game.isInProgress && !game.isLoading && !game.notEnabled">
			<p class="mb-0 white--text" :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-subtitle-1 font-weight-bold'">{{ selectedCards.length !== 4 ? 'Selecione 4 cartas do deck abaixo:' : 'Agora ordene as cartas na ordem que faça mais sentido para você.' }}</p>
			<p v-if="!$vuetify.breakpoint.mdAndUp && portraitMode" class="mb-0 mt-n2 white--text caption">Por favor, vire a tela na horizontal.</p>

			<!-- DISPLAY ON COMPUTER -->
			<template v-if="$vuetify.breakpoint.mdAndUp">
				<div class="animate__animated animate__fadeIn pa-12 pb-0" v-show="mainCardsSelection.length !== 80">
					<div class="d-flex justify-center" v-for="(data, index) in screenSizeSelection" :key="index" :class="index > 0 ? 'mt-n4' : ''">
						<template v-for="card in data">
							<v-card class="pa-1" :style="card.hidden ? 'visibility: hidden;' : ''" :key="`card_${card.id}`" style="width: 40px; height: 70px;" :id="`card-anim-${card.id}`" @click="selectCard(card)">
								<v-img :src="cardImage" :gradient="card.active ? 'to top, rgba(0, 0, 0, 0.6) 0%, transparent 72px' : ''" height="62"></v-img>
							</v-card>
						</template>
					</div>
				</div>
			</template>

			<!-- DISPLAY ON MOBILE -->
			<template v-if="$vuetify.breakpoint.smAndDown">
				<div class="animate__animated animate__fadeIn pa-2 pt-4" v-show="selectedCards.length !== 4">
					<div class="d-flex justify-center" v-for="(data, index) in mobileSizeSelection" :key="index" :class="index > 0 ? 'mt-n4' : ''">
						<template v-for="card in data">
							<v-card class="pa-1" :style="card.hidden ? 'visibility: hidden;' : ''" :key="`card_${card.id}`" style="width: 40px; height: 70px;" :id="`card-anim-${card.id}`" @click="selectCard(card)">
								<v-img :src="cardImage" :gradient="card.active ? 'to top, rgba(0, 0, 0, 0.6) 0%, transparent 72px' : ''" height="62"></v-img>
							</v-card>
						</template>
					</div>
				</div>
			</template>
			
			<v-divider v-if="selectedCards.length > 0 && $vuetify.breakpoint.mdAndUp" :class="$vuetify.breakpoint.mdAndUp ? 'my-6 mb-8 px-4' : 'my-2 px-2'"></v-divider>

			<!-- CARDS SELECTION AND REORDERING -->
			<div class="d-flex justify-center" v-if="$vuetify.breakpoint.mdAndUp && selectedCards.length > 1 ? true : !$vuetify.breakpoint.mdAndUp && selectedCards.length === 4">
				<div class="priority-bar mb-2" :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-5'" :style="`width: ${selectedCards.length > 0 ? (130*selectedCards.length)+128 : 0}px;`">
					<div class="d-flex white--text text-subtitle-2 font-weight-bold">
						<div class="pl-2" style="margin-top: -19px;"><v-icon small dark>mdi-chevron-left</v-icon> mais sentido</div>
						<div class="ml-auto pr-2" style="margin-top: -19px;">menos sentido <v-icon small dark>mdi-chevron-right</v-icon></div>
					</div>
				</div>
			</div>

			<div class="d-flex justify-center">
				<Container @drop="onDrop" orientation="horizontal" behaviour="contain">
					<Draggable v-for="(card, index) in selectedCards" :key="card.selectID">
						<div class="draggable-item-horizontal" :class="index > 0 ? 'pl-4' : ''" v-show="$vuetify.breakpoint.mdAndUp ? true : !$vuetify.breakpoint.mdAndUp && selectedCards.length === 4">
							<div class="flip-container" v-if="!card.flipped">
								<div class="flipper">
									<!-- CARD FRONT -->
									<v-card class="front" style="width: 130px; height: 200px; cursor: grab;">
										<v-img :src="cardImage" height="200"></v-img>
									</v-card>

									<!-- CARD BACK -->
									<v-card class="back" style="width: 130px; height: 200px; cursor: grab;">
										<div class="text-center pa-4" style="padding-top: 50px;">
											<span class="text-subtitle-2 grey--text text--darken-2 font-weight-bold">{{ card.name }}</span>
										</div>
									</v-card>
								</div>
							</div>

							<!-- CARD FLIPPED -->
							<v-card v-if="card.flipped" style="width: 130px; height: 200px; cursor: grab;" v-show="$vuetify.breakpoint.mdAndUp ? true : !$vuetify.breakpoint.mdAndUp && selectedCards.length === 4">
								<div class="text-center pa-4" style="padding-top: 50px;">
									<span class="text-subtitle-2 grey--text text--darken-2 font-weight-bold">{{ card.name }}</span>
								</div>
							</v-card>
						</div>
					</Draggable>
				</Container>
			</div>

			<!-- GAME STEPS -->
			<div class="d-flex justify-center" v-show="$vuetify.breakpoint.mdAndUp && selectedCards.length > 0 ? true : !$vuetify.breakpoint.mdAndUp && selectedCards.length === 4" :class="$vuetify.breakpoint.mdAndUp ? 'pt-6' : 'pt-3'">
				<v-btn :disabled="gameStepper === 1" color="primary" small dark @click="prevStep()" class="mr-2">
					<v-icon small class="mr-4">mdi-arrow-left</v-icon>
					Anterior
				</v-btn>
				<v-btn v-if="gameStepper !== gameCardsSelection.length" :loading="nextLoading" :disabled="this.gameCardsSelection[this.gameStepper - 1].selectedQuestions.length < 4 || gameStepper === gameCardsSelection.length" color="primary" small dark @click="nextStep()">
					Próximo
					<v-icon small class="ml-4">mdi-arrow-right</v-icon>
				</v-btn>
				<v-btn v-if="gameStepper === gameCardsSelection.length" :disabled="this.gameCardsSelection[this.gameStepper - 1].selectedQuestions.length < 4" color="primary" small dark @click="finishGame()">
					Próximo
					<v-icon small class="ml-4">mdi-arrow-right</v-icon>
				</v-btn>
			</div>
		</div>
	</v-container>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import '../../assets/css/animate.min.css'
import moment from 'moment'
import cardLogo from '../../assets/img/card-bg.jpg'

export default {
	name: 'FAC',
	components: {
		Container,
		Draggable
	},
	data() {
		return {
			userData: [],
			gameData: [],
			cardImage: cardLogo,

			allUserGames: [],
			gameProgression: [],
			portraitMode: false,

			// GAME SETTINGS
			nextLoading: false,
			gameStepper: 1,
			gameTimer: null,
			gameTimerLoading: false,
			gameTime: {
				minutes: 0,
				seconds: 0
			},
			gameCardsSelection: [],
			allGameCards: [],
			screenSizeSelection: [],
			mobileSizeSelection: [],
			selectedCards: [],
			mainCardsSelection: [],
			game: {
				isLoading: false,
				isFinished: false,
				isInProgress: false,
				isExpired: false,
				notEnabled: false,
				startedOn: null,
				finishedOn: null
			}
		}
	},
	mounted() {
		this.fetchData()
		
		if (screen.orientation && screen.orientation.type && screen.orientation.type.includes('portrait')) {
			this.portraitMode = true
		}
		screen.orientation.addEventListener('change', e => {
			this.setPortraitWarning()
		})

		if (!this.$vuetify.breakpoint.mdAndUp) {
			screen.orientation.lock('landscape')
		}
	},
	methods: {
		setPortraitWarning() {
			if (screen.orientation && screen.orientation.type && screen.orientation.type.includes('portrait')) {
				this.portraitMode = true
			}
			else {
				this.portraitMode = false
			}
		},
		shuffle(array) {
			let currentIndex = array.length, randomIndex
			while (0 !== currentIndex) {
				randomIndex = Math.floor(Math.random() * currentIndex)
				currentIndex--
				[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
			}

			return array
		},
		fetchData() {
			this.game.isLoading = true

			let shouldResetProgression = false
			Promise.resolve().then(() => {
				return this.$store.dispatch('checkAuth').then(result => {
					if (result && result.content && result.content.id) {
						this.userData = result.content
					}
				})
			}).then(() => {
				if (this.userData && this.userData.id) {
					return this.$store.dispatch('fetchUserGameProgress', this.userData.id).then(result => {
						if (result && result.length > 0) {
							this.allUserGames = result
							let unfinishedGames = []
							let hasPrototype = false
							let isFree = false

							// VALIDATE USER GAME
							for (let game of this.allUserGames) {
								if (!game.isFinished) {
									unfinishedGames.push(game)
								}
							}

							// PROCESS UNFINISHED GAMES
							if (unfinishedGames.length > 0) {
								this.gameProgression = unfinishedGames[unfinishedGames.length - 1] // LAST ONE

								// CHECK IF ITS IN PROGRESS
								if (this.gameProgression && this.gameProgression.id && this.gameProgression.isInProgress) {
									shouldResetProgression = true
								}
								else {
									if (this.gameProgression.accessType !== 1) {
										// GAME IS NOT FREE
										if (!this.gameProgression.isPaid && this.gameProgression.accessType !== 4) {
											this.game.notEnabled = true
										}
									}
								}
							}
						}
					})
				}
				else {
					this.game.isLoading = false
				}
			}).then(() => {
				if (!this.game.notEnabled) {
					return this.$store.dispatch('fetchGameFAC').then(result => {
						if (result && result.data) {
							// ACQUIRE GAME DATA
							this.gameData = {...result}

							if (this.gameData.cardGroups && this.gameData.cardGroups.length > 0) {
								// SET CARD MAIN ARRAYS
								for (let group of this.gameData.cardGroups) {
									group.gameQuestions = []
									group.selectedQuestions = []
									group.isFinished = false
								}

								// SET CARD PARAMETERS
								for (let group of this.gameData.cardGroups) {
									for (let quest of group.questions) {
										for (let question of this.gameData.cards) {
											question.active = false
											question.hidden = false
											if (quest === question.id) {
												group.gameQuestions.push(question)
											}
										}
									}
								}

								// SET ALL CARDS
								this.allGameCards = JSON.parse(JSON.stringify(this.gameData.cards))

								// CHUNK RESULTS FOR SCREEN AND MOBILE
								this.screenSizeSelection = []
								this.mobileSizeSelection = []
								let tempScreen = []
								let tempMobile = []
								if (this.allGameCards.length === 80) {
									tempScreen = this.allGameCards.reduce((all, one, i) => {
										const ch = Math.floor(i / 40)
										all[ch] = [].concat((all[ch] || []), one)

										return all
									}, [])

									tempMobile = this.allGameCards.reduce((all, one, i) => {
										const ch = Math.floor(i / 20)
										all[ch] = [].concat((all[ch] || []), one)

										return all
									}, [])

									this.screenSizeSelection = [...tempScreen]
									this.mobileSizeSelection = [...tempMobile]
								}
							}
						}
					})
				}
				else {
					return true
				}
			}).then(() => {
				if (!this.game.notEnabled) {
					if (!shouldResetProgression) {
						this.game.isLoading = false
						this.startGame()
					}
					else {
						this.resetToGameProgression(this.gameProgression)
					}
				}
				else {
					this.game.isLoading = false
				}
			}).catch(() => {})
		},
		applyDrag(arr, dragResult) {
			const { removedIndex, addedIndex, payload } = dragResult
			if (removedIndex === null && addedIndex === null) return arr

			const result = [...arr]
			let itemToAdd = payload

			if (removedIndex !== null) {
				itemToAdd = result.splice(removedIndex, 1)[0]
			}

			if (addedIndex !== null) {
				result.splice(addedIndex, 0, itemToAdd)
			}

			return result
		},
		onDrop(dropResult) {
			this.selectedCards = this.applyDrag(this.selectedCards, dropResult)
		},
		startTimer(duration) {
			let timer = 0
			let minutes = 0
			let seconds = 0
			this.gameTimerLoading = true

			this.gameTimer = setInterval(() => {
				// TRUNCATES
				minutes = parseInt(timer / 60, 10)
				seconds = parseInt(timer % 60, 10)

				// ADD ZERO
				minutes = minutes < 10 ? '0' + minutes : minutes
				seconds = seconds < 10 ? '0' + seconds : seconds

				// DISPLAY
				this.gameTime.minutes = minutes
				this.gameTime.seconds = seconds

				if (--timer < 0) {
					timer = duration
				}
			}, 1000)
			
			setTimeout(() => {
				this.gameTimerLoading = false
			}, 1200)
		},
		startGame() {
			if (this.userData && this.userData.id && this.game.isInProgress === false && this.game.isFinished === false && this.gameData.cardGroups.length > 0) {
				this.game.startedOn = moment().format('YYYY-MM-DD HH:mm:ss')
				this.game.isInProgress = true

				// GAME CARDS
				let definedQuestions = JSON.parse(JSON.stringify(this.gameData.cardGroups))
				if (this.gameData.data.isRandom) {
					this.gameCardsSelection = this.shuffle(definedQuestions)
				}
				else {
					this.gameCardsSelection = definedQuestions
				}

				// START TIMER
				this.startTimer(this.gameData.data.timeLimit * 60)

				// OPEN DECK OF CARDS
				this.$nextTick(() => {
					for (let card of this.allGameCards) {
						const node = document.getElementById('card-anim-'+card.id)
						node.classList.add('card-open')
					}
				})
			}
		},
		animateCardIndex(active, index) {
			// ANIMATES THE CARD BY ADDING A CLASS
			const node = document.getElementById('card-anim-'+index)
			if (active) {
				node.classList.remove('card-move-down')
				node.classList.add('card-move-up')
				node.classList.add('orange', 'lighten-2')
			}
			else {
				node.classList.remove('card-move-up')
				node.classList.add('card-move-down')
				node.classList.remove('orange', 'lighten-2')
			}
		},
		isEmpty(data) {
			return data === null || data === undefined || data === 'undefined' || data === '' || data === ' '
		},
		selectCard(card) {
			// ONLY SELECT 4
			setTimeout(() => {
				if (this.selectedCards.length < 4) {
					// TOGGLE AND ANIMATE
					card.active = !card.active
					this.animateCardIndex(card.active, card.id)

					if (card.active && this.selectedCards.length < 4) {
						// GET A NON-SELECTED GAME RELATED CARD
						let unusedCard = null
						for (let question of this.gameCardsSelection[this.gameStepper - 1].gameQuestions) {
							if (this.isEmpty(question.used) || !question.used) {
								unusedCard = question
								break
							}
						}

						// MANAGE ADDITION TO GAME TABLES
						setTimeout(() => {
							// SET TAGS
							unusedCard.used = true

							// ADD TO SELECTION
							this.selectedCards.push(unusedCard)
						}, 200)
					}
					else {
						// REMOVE FROM SELECTION
						let usedCard = this.selectedCards.pop()
						if (usedCard && usedCard.id) {
							for (let card of this.gameCardsSelection[this.gameStepper - 1].gameQuestions) {
								if (card.id === usedCard.id) {
									card.used = false
								}
							}
						}
					}
				}
			}, 100)
		},
		prevStep() {
			if (this.gameStepper > 0) {
				// REMOVE STEP
				this.gameStepper--

				// RESET SELECTION
				this.selectedCards = []

				// RESET ALL CARDS ANIMATIONS
				for (let card of this.allGameCards) {
					card.active = false
					this.animateCardIndex(false, card.id)
				}

				// RESET CARDS TO PREVIOUS STEP CONFIGURATION
				let previousCards = this.gameCardsSelection[this.gameStepper - 1].selectedQuestions
				if (previousCards.length > 0) {
					for (let card of previousCards) {
						// ADD PREVIOUS CARDS
						this.selectedCards.push(card)
					}
				}
			}
		},
		nextStep() {
			if (this.gameStepper <= this.gameCardsSelection.length) {
				this.nextLoading = true
				setTimeout(() => {
					// ADD STEP
					this.gameStepper++

					// RESET SELECTION
					this.selectedCards = []

					// RESET ALL CARDS ANIMATIONS
					for (let card of this.allGameCards) {
						card.active = false
						this.animateCardIndex(false, card.id)
					}

					// RESET CARDS TO STEP CONFIGURATION IF EXISTS
					let nextCards = this.gameCardsSelection[this.gameStepper - 1].selectedQuestions
					if (nextCards.length > 0) {
						for (let card of nextCards) {
							// ADD NEXT CARDS
							this.selectedCards.push(card)
						}
					}

					// SAVE PROGRESSION
					this.setGameProgression(this.gameCardsSelection)
					this.nextLoading = false
				}, 600)
			}
		},
		markAsFlipped() {
			// MARK AS FLIPPED
			for (let card of this.selectedCards) {
				card.flipped = true
			}
		},
		flipCards() {
			if (this.selectedCards.length > 0 && this.selectedCards.length === 4) {
				let isFlipped = this.selectedCards[0].flipped
				if (!isFlipped) {
					// WAIT TO FLIP
					setTimeout(() => {
						// FLIP IT
						const node = document.getElementsByClassName('flip-container')
						for (let nd of node) {
							nd.classList.toggle('flip')
						}

						// MARK SELECTION AS HIDDEN
						this.allGameCards.forEach((card, index) => {
							if (card.active) {
								// ANIMATE FADE-OUT
								const ID = document.getElementById('card-anim-'+card.id)
								ID.classList.add('animate__animated', 'animate__fadeOut')

								// SET HIDDEN
								card.hidden = true
								this.mainCardsSelection.push(card)
							}
						})

						setTimeout(() => {
							this.markAsFlipped()
						}, 250)
					}, 450)
				}
			}
		},
		finishGame() {
			// HAS TIME LEFT
			if ((Number(this.gameTime.minutes) > 0 && Number(this.gameTime.seconds) > 0) || (Number(this.gameTime.minutes) === 0 && Number(this.gameTime.seconds) > 0)) {
				// VALIDATE GAME ARRAY
				this.game.isLoading = true
				let validStep = true
				for (let step of this.gameCardsSelection) {
					if (step.selectedQuestions.length !== 4) {
						validStep = false
					}
					for (let question of step.selectedQuestions) {
						if (!question.score) {
							validStep = false
						}
					}
				}

				// MOUNT GAME ARRAY
				if (validStep && this.userData && this.gameProgression) {
					this.game.finishedOn = moment().format('YYYY-MM-DD HH:mm:ss')
					this.game.isInProgress = false
					this.game.isFinished = true
					let timeRemaining = 0

					// CALCULATE TIME LEFT
					if (moment(this.game.startedOn).isValid() && this.gameData.data) {
						let timeDiffSeconds = moment().diff(moment(this.game.startedOn), 'seconds')
						timeRemaining = (this.gameData.data.timeLimit * 60) - timeDiffSeconds
					}

					// GAME OBJECT
					let finishedGameData = {
						id: this.userData.id,
						email: this.userData.email,
						gameID: this.gameProgression.id,
						progressionID: this.gameProgression.progress.id,
						isInProgress: false,
						isFinished: true,
						startedOn: this.game.startedOn,
						finishedOn: this.game.finishedOn,
						step: this.gameStepper,
						timeLeft: timeRemaining.toFixed(0),
						questionScores: [],
						questionScoresByDimension: [],
						questionScoresByGroup: []
					}

					// SET GROUP OBJECT
					for (let group of this.gameData.groups) {
						finishedGameData.questionScoresByGroup.push({
							id: group.id,
							score: 0
						})
					}

					// SET DIMENSION OBJECT
					for (let dimension of this.gameData.dimensions) {
						finishedGameData.questionScoresByDimension.push({
							id: dimension.id,
							group: dimension.group,
							score: 0,
							formattedScore: 0
						})
					}

					// SET SCORES AND POPULATE
					for (let step of this.gameCardsSelection) {
						for (let question of step.selectedQuestions) {
							finishedGameData.questionScores.push({
								id: question.id,
								group: question.group,
								dimension: question.dimension,
								score: question.score
							})

							for (let group of finishedGameData.questionScoresByGroup) {
								if (question.group === group.id) {
									group.score = group.score + question.score
								}
							}

							for (let dimension of finishedGameData.questionScoresByDimension) {
								if (question.dimension === dimension.id) {
									dimension.score = dimension.score + question.score
								}
							}
						}
					}

					// FORMAT SCORE
					for (let dimension of finishedGameData.questionScoresByDimension) {
						if (dimension.id !== 4) {
							if (dimension.score === 5) {
								dimension.formattedScore = 1
							}
							else if (dimension.score === 6 || dimension.score === 7) {
								dimension.formattedScore = 2
							}
							else if (dimension.score === 8) {
								dimension.formattedScore = 3
							}
							else if (dimension.score === 9 || dimension.score === 10) {
								dimension.formattedScore = 4
							}
							else if (dimension.score === 11 || dimension.score === 12) {
								dimension.formattedScore = 5
							}
							else if (dimension.score === 13) {
								dimension.formattedScore = 6
							}
							else if (dimension.score === 14 || dimension.score === 15) {
								dimension.formattedScore = 7
							}
							else if (dimension.score === 16 || dimension.score === 17) {
								dimension.formattedScore = 8
							}
							else if (dimension.score === 18 || dimension.score === 19) {
								dimension.formattedScore = 9
							}
							else if (dimension.score === 20) {
								dimension.formattedScore = 10
							}
						}
						else {
							if (dimension.score <= 11) {
								dimension.formattedScore = 1
							}
							else if (dimension.score > 11 && dimension.score <= 20) {
								dimension.formattedScore = dimension.score - 10
							}
						}
					}

					// FINISHED GAME
					this.$store.dispatch('setUserGameFinished', finishedGameData).then(() => {
						this.game.isLoading = false
					})
				}
			}
			else {
				this.expireGameProgression()
			}
		},
		expireGameProgression() {
			if (this.userData && this.userData.id) {
				this.$bus.$emit('DISABLE_TIMER')
				this.gameTimer = null
				this.game.isExpired = true

				let gameProgression = {
					id: this.userData.id,
					gameID: this.gameProgression.id,
					progressionID: this.gameProgression.progress.id,
					startedOn: null,
					isInProgress: false,
					isFinished: false,
					step: 1,
					questions: null,
					selectedCards: null,
					hiddenCards: null
				}
				this.$store.dispatch('setUserGameProgression', gameProgression)
			}
		},
		setGameProgression(selection) {
			if (this.userData && this.userData.id && this.gameData && this.gameData.data && this.gameProgression && this.gameProgression.id && this.game.isInProgress && selection.length > 0) {
				let gameProgression = {
					id: this.userData.id,
					gameID: this.gameProgression.id,
					progressionID: this.gameProgression.progress.id,
					startedOn: this.game.startedOn,
					isInProgress: this.game.isInProgress,
					isFinished: this.game.isFinished,
					step: this.gameStepper,
					questions: [],
					selectedCards: [],
					hiddenCards: []
				}

				for (let step of selection) {
					// SAVE GENERATED QUESTION ORDER
					gameProgression.questions.push(step.id)
				}

				// SAVE SELECTION PROGRESSION (ALL)
				gameProgression.selectedCards = selection

				// SAVE HIDDEN CARDS
				for (let card of this.mainCardsSelection) {
					gameProgression.hiddenCards.push(card.id)
				}

				if (gameProgression.selectedCards.length > 0) {
					this.$store.dispatch('setUserGameProgression', gameProgression)
				}
			}
		},
		resetToGameProgression(data) {
			if (data.progress && data.progress.step > 0) {
				// CHECK TIME
				this.gameTimer = null
				let isValidTime = true
				let timeRemaining = 0
				if (moment(data.dateStarted).isValid() && this.gameData.data) {
					let timeDiffSeconds = moment().diff(moment(data.dateStarted), 'seconds')
					timeRemaining = (this.gameData.data.timeLimit * 60) - timeDiffSeconds

					if (timeRemaining <= 0) {
						isValidTime = false
					}
				}

				if (isValidTime && this.gameData && this.userData) {
					// RESET GAME SELECTIONS FROM DEFINED
					this.gameCardsSelection = JSON.parse(JSON.stringify(data.progress.cardsSelected))

					// SET GAME CONFIG
					this.game.startedOn = data.dateStarted
					this.game.isInProgress = data.isInProgress
					this.game.isFinished = data.isFinished

					// RESET SELECTION
					this.selectedCards = []

					// SET STEP
					this.gameStepper = data.progress.step

					// SET CARDS
					let setCards = this.gameCardsSelection[this.gameStepper - 1].selectedQuestions
					if (setCards && setCards.length > 0) {
						for (let card of setCards) {
							this.selectedCards.push(card)
						}
					}

					// HIDE CARDS
					if (data.progress.cardsHidden.length > 0) {
						for (let card of this.allGameCards) {
							for (let cd of data.progress.cardsHidden) {
								if (card.id === cd) {
									card.hidden = true
									this.mainCardsSelection.push(card)
								}
							}
						}
					}

					// OPEN DECK OF CARDS
					this.game.isLoading = false
					this.$nextTick(() => {
						for (let card of this.allGameCards) {
							const node = document.getElementById('card-anim-'+card.id)
							node.classList.add('card-open')
						}

						// START TIMER
						this.startTimer(timeRemaining)
					})
				}
				else {
					this.game.isLoading = false
					this.expireGameProgression()
				}
			}
		}
	},
	watch: {
		'gameTime.seconds'() {
			this.$bus.$emit('REFRESH_TIMER', this.gameTime)
			if (this.gameTimer && !this.gameTimerLoading && Number(this.gameTime.minutes) === 0 && Number(this.gameTime.seconds) === 0) {
				this.expireGameProgression()
			}
		},
		selectedCards() {
			if (this.game.isInProgress) {
				// PROCESS FLIPPING
				this.flipCards()

				// SET SCORE
				for (let i = 0; i < this.selectedCards.length; i++) {
					this.selectedCards[i].score = this.selectedCards.length - i
				}

				// SET GAME STEP WITH NEW SELECTIONS
				this.gameCardsSelection[this.gameStepper - 1].selectedQuestions = [...this.selectedCards]
			}
		},
		'$vuetify.breakpoint.mdAndUp'() {
			// WATCH IF ORIENTATION OR DEVICE CHANGES
			if (this.game.isInProgress) {
				// OPEN DECK OF CARDS
				this.$nextTick(() => {
					for (let card of this.allGameCards) {
						const node = document.getElementById('card-anim-'+card.id)
						node.classList.add('card-open')
					}
				})
			}
		}
	}
}
</script>

<style scoped>
/* SELECTION PRIORITY BAR */
.priority-bar {
	height: 10px;
	border-radius: 35px;
	background: linear-gradient(to right, #3fffa2 0%, #ffdb3a 45%, #e5405e 100%);
}

/* CARD MOVEMENT */
.card-open {
	transition: margin 0.5s;
	margin: 0 -12px;
}
.card-move-up {
	transition: 0.5s;
	transform: translateY(-15px);
}
.card-move-down {
	transition: 0.5s;
	transform: translateY(0px);
}
.highIndex {
	z-index: 999;
}

/* FLIPPER */
.flip-container {
	perspective: 1000px;
}
.flip-container.flip .flipper {
    transform: rotateY(180deg);
}
.flip-container,
.front,
.back {
    width: 130px;
    height: 200px;
}
.flipper {
    transition: 0.8s;
    transform-style: preserve-3d;
	position: relative;
}
.front,
.back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.front {
    z-index: 2;
    transform: rotateY(0deg);
}
.back {
    transform: rotateY(180deg);
}
</style>
